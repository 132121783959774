import { render, staticRenderFns } from "./DispatchStatusSelect.vue?vue&type=template&id=d093dcca&scoped=true&"
import script from "./DispatchStatusSelect.vue?vue&type=script&lang=js&"
export * from "./DispatchStatusSelect.vue?vue&type=script&lang=js&"
import style0 from "./DispatchStatusSelect.vue?vue&type=style&index=0&id=d093dcca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d093dcca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VChip,VSelect})
